import { useEffect } from 'react'
import styled from 'styled-components'

export default function PokemonSearch() {
  useEffect(() => {
    const form: HTMLFormElement = document.querySelector('#form-wrapper > form')!
    const input: HTMLInputElement = document.querySelector('#form-wrapper > form > input')!
    const button: HTMLButtonElement = document.querySelector('#form-wrapper > form > button')!
    const text: HTMLParagraphElement = document.querySelector('#form-wrapper > p')!
    const image: HTMLImageElement = document.querySelector('#form-wrapper > img')!

    function capitalizeFirstLetter(string: string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    
    form.addEventListener('submit', event => {
      event.preventDefault()
    
      const pokemonName = input.value.toLowerCase()
    
      if (pokemonName === '') return
    
      text.innerText = 'Searching...'
      image.src = ''
      button.disabled = true
    
      fetch(`https://pokeapi.co/api/v2/pokemon/${pokemonName}`).then(response => {
        button.disabled = false
    
        if (response.status === 404) {
          text.innerText = 'Nothing found...'
          return
        }
    
        response.json().then(pokemonData => {
          text.innerText = `Found ${capitalizeFirstLetter(pokemonData.name)}!`
          image.src = pokemonData.sprites.front_default
        })
      })
    })
  }, [])
  
  return (
    <Wrapper id="form-wrapper">
      <form>
        <input placeholder="Pokémon name" />
        <button>Search</button>
      </form>

      <img src="" />
      <p></p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  
  min-height: 156px;
  padding: 8px;
  border: 1px solid;
  border-radius: 4px;
  
  font-family: sans-serif;

  button {
    margin-left: 8px;

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  p {
    min-height: 21px;
    margin: auto 0 0;
  }
`
