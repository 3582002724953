import { ThemeProvider } from 'styled-components'
import { styledKitMediaQueries } from 'styles'

import 'styles/main.css'
import 'styles/prism-dracula.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'

import packageJson from './package.json'

console.log(`v${packageJson.version}`)

export const wrapPageElement = ({ element, props }) => (
  <ThemeProvider theme={{ styledKitMediaQueries }} {...props}>
    {element}
  </ThemeProvider>
)
