// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-get-started-with-cypress-md": () => import("./../../../src/pages/get-started-with-cypress.md" /* webpackChunkName: "component---src-pages-get-started-with-cypress-md" */),
  "component---src-pages-hire-me-tsx": () => import("./../../../src/pages/hire-me.tsx" /* webpackChunkName: "component---src-pages-hire-me-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-your-first-web-application-md": () => import("./../../../src/pages/your-first-web-application.md" /* webpackChunkName: "component---src-pages-your-first-web-application-md" */)
}

